// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.LOADING_STATE
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.UPDATE_USER;
    payload: {} | string;
}

interface Avatar {
    id: number;
    key: string;
    nome: string;
    path: string;
    tipoarquivo_id: number;
}

interface GrupoSistema {
    funcoes: any[];
    id: number;
    nome: string;
    unidade_id: number;
}

interface Usuario {
    ativo: boolean;
    avatar: Avatar;
    celular: string;
    colaborador_id: number;
    createdAt_: string;
    email: string;
    gruposistema: GrupoSistema;
    id: number;
    nome: string;
    unidade_id: number;
    usuario: string;
}

interface UserData {
    token: string;
    usuario: Usuario;
}

// common success
export const authApiResponseSuccess = (
    actionType: string,
    data: UserData | {}
): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data }
});
// common error
export const authApiResponseError = (
    actionType: string,
    error: string
): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error }
});

export const loginUser = (usuario: string, senha: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { usuario, senha }
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {}
});

export const signupUser = (
    fullname: string,
    email: string,
    password: string
): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname, email, password }
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {}
});

export const setLoadingState = (loadingState: boolean): AuthActionType => ({
    type: AuthActionTypes.LOADING_STATE,
    payload: { loadingState }
});

export const updateAppUser = (data: Usuario): AuthActionType => ({
    type: AuthActionTypes.UPDATE_USER,
    payload: { data }
});
