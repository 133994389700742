import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { usuario: string; senha: string }) {
    const baseUrl = "/session/";
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = "/logout/";
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; senha: string }) {
    const baseUrl = "/register/";
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = "/esqueci-senha";
    return api.create(`${baseUrl}`, params);
}

function updatePassword(hash: string, params: { senha: string }) {
    return api.update(`/esqueci-senha/${hash}`, params);
}

export { login, logout, signup, forgotPassword, updatePassword };
